<template>
  <div class="data-table">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" class="table-data" :row-style="{ height: '57px' }">
      <!-- 表格列定义 -->
      <el-table-column prop="faultCategory" :label=" $t('ProductManage.ConfigManage.faultCategories')" ></el-table-column>
      <el-table-column prop="faultSubCategory" :label="$t('ProductManage.ConfigManage.faultSubcategory')" ></el-table-column>
      <el-table-column prop="hasDynamicDiagnosis" :label="$t('ProductManage.ConfigManage.associationDynamicDiagnosis')" ></el-table-column>

      <el-table-column prop="quickDiagnosis" :label=" $t('ProductManage.ConfigManage.quickDiagnosis')"
      >
        <template v-slot="scope">
          <el-link :underline="false" :href="scope.row.quickDiagnosis" target="_blank">
            <span class="link-text-style"> {{ scope.row.quickDiagnosis }}</span>
          </el-link>
        </template>
      </el-table-column>

      <el-table-column :label="$t('ProductManage.ConfigManage.operation')" >
        <template v-slot="scope">
          <el-popconfirm :title=" $t('ProductManage.ConfigManage.sureDelete')" placement="top" @confirm="confirmDelete(scope.row)"
                         :confirm-button-text="$t('ProductManage.ConfigManage.confirm')"
                         :cancel-button-text=" $t('ProductManage.ConfigManage.cancel')"
                         confirm-button-type="success"
                         width="250"
          >
            <template #reference>
              <el-button link><span class="link-text-delete">{{$t('ProductManage.ConfigManage.delete')}}</span></el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>
  </div>

  <!--编辑弹框-->
  <el-dialog
      :title=" $t('ProductManage.ConfigManage.edit')"
      v-model="addDialogVisible"
      width="25%"
      style="text-align: left;"
      @closed="resetForm"
  >
    <!-- 弹窗内容 -->
    <el-form :model="motorForm" ref="motorForm" :rules="motorFormRules" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="24">

          <el-form-item :label="$t('ProductManage.ConfigManage.faultName')" prop="faultName">
            <el-input v-model="motorForm.faultName" :placeholder="$t('ProductManage.ConfigManage.faultNamePlaceholder')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">

          <el-form-item :label="$t('ProductManage.ConfigManage.helpManual')" prop="userManualLink">
            <!-- 上传附件的代码，可以使用 el-upload -->
            <el-upload v-model="motorForm.userManualLink" class="upload-demo"
                        :on-change="handleChange">
              <el-button class="button-background-color">{{$t('ProductManage.ConfigManage.uploadConfigurationDocument')}}</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  jpg/png/webp files with a size less than 500kb
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="addDialogVisible = false"  class="custom-reset-button"> {{$t('ProductManage.ConfigManage.cancel')}}</el-button>
      <el-button class="button-background-color" @click="addMotorData"> {{$t('ProductManage.ConfigManage.confirm')}}</el-button>
    </div>
  </el-dialog>

</template>

<script>
import resizeMixin from "@/mixins/resizeMixin";
import dialogMixin from "@/mixins/dialogMixin";
import {deleteFaultType} from "@/api/api";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot},
  mixins: [resizeMixin,dialogMixin],

  data() {
    return {
      addDialogVisible: false,
      motorForm: {
        faultCategory : '',
        faultSubCategory : '',
        hasDynamicDiagnosis : '',
        quickDiagnosis : '',
      },
      motorFormRules: {
        faultName: [
          { required: true, message: this.$t('ProductManage.ConfigManage.faultNameRequired'), trigger: 'change' },
          { pattern: /^[A-Za-z0-9_.-]+$/, message: this.$t('ProductManage.ConfigManage.faultNamePattern'), trigger: 'blur' },
        ],
      },
    };
  },
  props: {
    tableData: Array, // 电机数据
  },
  emits: ['editMotor', 'deleteMotorFaultType'], // 在这里声明自定义事件
  methods: {
    editMotor() {
      // 弹出编辑弹框并填充表单数据
      this.motorForm = {
        faultName: '',
      };
      this.addDialogVisible = true;
    },
    async confirmDelete(row) {
      try {
        await deleteFaultType(row.id); // 调用删除接口
        this.$message.success(this.$t('ProductManage.ConfigManage.deleteSuccess'));
        this.$emit('deleteMotorFaultType'); // 触发删除事件，通知父组件刷新数据
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.deleteError'));
      }
    },

    resetForm() {
      this.$refs.motorForm.resetFields();
    },
    handleDeleteClose(done) {
      // 关闭确认删除弹框时重置状态
      this.motorToDelete = null;
      done();
    },
    addMotorData() {
      this.addDialogVisible = false;
    },
    handleChange() {

    },
  },
};
</script>

<style scoped>

:deep(.el-upload-list){
  width: 500px;
}
:deep(.el-upload-list__item-name){
  width: 300px;
}
.data-table {
  margin: 10px 20px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 5px 10px; /* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 15px 30px; /* 在中等屏幕下增加间隔 */
  }

  /* 添加其他屏幕尺寸的媒体查询和 margin 设置 */
}

.table-data {
  width: 100%;
  margin-top: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
