<template>
  <div class="add-motor">
    <el-button class="button-background-color" @click="showAddDialog">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp; {{ $t('ProductManage.ConfigManage.add') }}
    </el-button>


    <el-dialog
        style="text-align: left;"
        :title="$t('ProductManage.ConfigManage.add')"
        v-model="addDialogVisible"
        width="600"
        @closed="resetForm" @open="disableScroll" @close="enableScroll"
    >
      <hr class="top-separator"/>

      <!-- 弹窗内容 -->
      <el-form :model="ParameterFileForm" ref="ParameterFileForm" :rules="ParameterFileFormRules" label-width="100px"
               label-position="top">
        <el-row :gutter="20">


          <el-col :span="24">

            <el-form-item prop="compatibleOrganization">
              <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('ProductManage.ConfigManage.compatibilityOrganization') }}</label>
              <el-tree-select
                  v-model="ParameterFileForm.compatibleOrganization"
                  :data="organizationOptions"
                  :props="{ label: 'label', children: 'children' }"
                  show-checkbox
                  default-expand-all
                  multiple
                  check-strictly
                  check-on-click-node
                  :placeholder="$t('ProductManage.ConfigManage.compatibilityOrganizationPlaceholder')"
                  :render-after-expand="false"
                  clearable
                  style="width: 100%;"
                  filterable

              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="compatibleModel">
              <label> <span style="color: rgb(245,108,108)">*</span> {{$t('ProductManage.ConfigManage.compatibleModel')}}</label>

              <el-select
                  v-model="ParameterFileForm.compatibleModel"
                  multiple
                  :placeholder="$t('ProductManage.ConfigManage.compatibleModelPlaceholder')"
                  style="width: 100%;"
                  filterable
              >
                <el-option
                    v-for="item in compatibleModelsOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ConfigManage.documentName')" prop="fileName">
              <el-input v-model="ParameterFileForm.fileName"
                        :placeholder=" $t('ProductManage.ConfigManage.documentNamePlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">

            <el-form-item :label="$t('ProductManage.ConfigManage.documentDescription')" prop="fileDescription">
              <el-input type="textarea" v-model="ParameterFileForm.fileDescription"
                        :rows="3"
                        :placeholder="$t('ProductManage.ConfigManage.documentDescriptionPlaceholder')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">

            <el-form-item label="" prop="fileList" style="margin-top: 7px;margin-bottom: 5px;">
              <!-- 上传附件的代码，可以使用 el-upload -->
              <el-upload v-model:file-list="ParameterFileForm.fileList" class="upload-demo"
                         :limit="1"
                         :before-upload="handleBeforeUpload"
                         accept=".bin">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ProductManage.ConfigManage.uploadConfigurationDocument') }}
                </el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <hr class="dialog-separator"/>

      <div class="dialog-footer">
        <el-button @click="addDialogVisible = false" class="custom-reset-button">
          {{ $t('ProductManage.ConfigManage.cancel') }}
        </el-button>
        <el-button class="button-background-color" @click="addParameterFileData">
          {{ $t('ProductManage.ConfigManage.confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Plus, Upload} from "@element-plus/icons-vue";
import {
  addFirmware,
  getOrganizationsData,
  getProductModelData,
  listToTree,
  uploadFileData
} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [dialogMixin],
  components: {Upload, Plus},
  data() {
    return {
      addDialogVisible: false,
      ParameterFileForm: {
        fileName: '',
        compatibleModel: [],
        compatibleOrganization: [],
        fileDescription: '',
        fileList: [],
        uploadedFile: '', // 用于存储单个文件标识符
        fileSize: 0
      },
      ParameterFileFormRules: {
        fileName: [
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.firmwareNamePattern'),

            trigger: 'blur',
          },
        ],
        compatibleModel: [
          {required: true, message: this.$t('ProductManage.compatibleModelRequired'), trigger: 'change'},
        ],
        compatibleOrganization: [
          {required: true, message: this.$t('ProductManage.compatibleOrganizationRequired'), trigger: 'change'},
        ],

      },
      attrs: {
        multiple: true,
      },
      organizationOptions: [],
      selectWidth: 'auto',
      compatibleModelsOptions: [],

    };
  },
  props: {
    loadData: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.fetchOrganizationOptions();
    this.fetchMotorCompatibleModels();
  },
  methods: {
    showAddDialog() {
      this.addDialogVisible = true;
    },
    resetForm() {
      this.$refs.ParameterFileForm.resetFields();
    },
    async addParameterFileData() {
      // 先进行表单验证
      await this.$refs.ParameterFileForm.validate(async (valid) => {
        if (valid) {
          // 表单验证通过后，检查是否选择了文件
          if (this.ParameterFileForm.fileList.length === 0 || !this.ParameterFileForm.uploadedFile) {
            this.$message.error(this.$t('ProductManage.selectParameterFile'));
            return;
          }
          // 尝试执行文件上传和数据提交
          try {
            const formData = {
              type: 1, // 根据你的系统设计调整类型
              name: this.ParameterFileForm.fileName,
              model_ids: this.ParameterFileForm.compatibleModel,
              organization_ids: this.ParameterFileForm.compatibleOrganization,
              desc: this.ParameterFileForm.fileDescription,
              file: this.ParameterFileForm.uploadedFile,
              file_name: this.ParameterFileForm.fileList[0].name,
              file_size: this.ParameterFileForm.fileSize
            };
            const response = await addFirmware(formData); // 根据实际API调整函数名和参数
            if (response?.code === 0) { // 假设 code 0 表示成功
              this.$message.success(this.$t('ProductManage.parameterFileAdded'));
              this.addDialogVisible = false;
              this.resetForm();
              await this.loadData();
            } else {
              this.$message.error(response?.message || this.$t('ProductManage.addDataFailed'));
            }
          } catch (error) {
            this.$message.error(this.$t('ProductManage.retryAddDataFailed'));
          }
        } else {
          this.$message.error(this.$t('ProductManage.checkInputValidation'));

        }
      });
    },


    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.fetchOrganizationFailed'));
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    },
    async fetchMotorCompatibleModels() {
      try {
        const response = await getProductModelData(0, 1);
        this.compatibleModelsOptions = response.data;
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.fetchDataFail'));
      }
    },
    async handleBeforeUpload(file) {
      // 这里可以执行你的上传逻辑
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);

        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ProductManage.uploadSuccess'));

          // 假设后端响应包含文件的URL和名称
          const fileData = response;
          this.ParameterFileForm.fileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.ParameterFileForm.uploadedFile = fileData.key; // 假设 response.key 是文件标识符
          this.ParameterFileForm.fileSize = fileData.fileSize;
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ProductManage.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ProductManage.uploadException'));
      }
      return false;
    },
  },
};
</script>

<style scoped>
:deep(.el-upload-list){
  width: 500px;
}
:deep(.el-upload-list__item-name){
  width: 300px;
}
/* 去除弹框内容的内边距 */
:deep(.el-dialog__body ){
  padding-bottom: 0; /* 或者您想要的较小的值 */
}

/* 如果您还想调整弹框的顶部、左侧或右侧内边距 */
:deep( .el-dialog__body ){
  padding: 20px 15px 5px 20px; /* 顶部、左右侧保持 20px 内边距，底部为 0 */
}

/* 如果 .el-dialog 有额外的样式需要覆盖 */
:deep(.el-dialog ){
  padding: 0; /* 如果您可以控制弹窗的内边距，将其设置为0 */
}

:deep( .el-form-item__label ){
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.add-motor {
  text-align: left;
  margin-top: 5px;
  margin-left: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
