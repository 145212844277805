<template>
  <div>
    <div class="add-motor">

      <el-button class="button-background-color" @click="openDialog">
        <el-icon>
          <Plus/>
        </el-icon>&nbsp; {{ $t('ProductManage.ConfigManage.add') }}
      </el-button>
    </div>

    <el-dialog v-model="dialogVisible" :title="$t('ProductManage.ConfigManage.newFaultCode')" :width="1200" style="text-align: left" @open="disableScroll"
               @close="enableScroll">

      <div class="steps-container">
        <el-steps :active="activeStep" finish-status="success" align-center>
          <el-step :title="$t('ProductManage.ConfigManage.faultCode')" @click="changeStep(0)"></el-step>
          <el-step :title="$t('ProductManage.ConfigManage.quickDiagnosis')" @click="changeStep(1)"></el-step>
        </el-steps>
      </div>
      <div>
        <el-form ref="form" :model="formData" label-width="150px">
          <el-row v-if="activeStep === 0" :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('ProductManage.ConfigManage.zh')" prop="nameCn"
                            :rules="[{ required: true, message: $t('ProductManage.ConfigManage.faultNameCnMessage') }]">
                <el-input v-model="formData.nameCn" :placeholder="$t('ProductManage.ConfigManage.faultNameCnPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('ProductManage.ConfigManage.desc')" prop="zh_desc"
                            :rules="[{ required: false }]">
                <el-input v-model="formData.zh_desc" :placeholder="$t('ProductManage.ConfigManage.descCnPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="activeStep === 0" :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('ProductManage.ConfigManage.en')" prop="nameEn"
                            :rules="[{ required: true, message: $t('ProductManage.ConfigManage.faultNameEnMessage') }]">
                <el-input v-model="formData.nameEn" :placeholder="$t('ProductManage.ConfigManage.faultNameEnPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('ProductManage.ConfigManage.desc')" prop="en_desc"
                            :rules="[{ required: false }]">
                <el-input v-model="formData.en_desc" :placeholder="$t('ProductManage.ConfigManage.descEnPlaceholder')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 其他语言的输入框 -->
          <template v-if="activeStep === 0">
            <el-row v-for="lang in languages" :key="lang.prop" :gutter="20">
              <el-col :span="12">
                <el-form-item :label="$t(lang.label)" :prop="lang.prop">
                  <el-input :placeholder="$t(lang.placeholder)" v-model="formData[lang.prop]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t(lang.descLabel)" :prop="lang.descProp">
                  <el-input :placeholder="$t(lang.descPlaceholder)" v-model="formData[lang.descProp]"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <el-form-item v-if="activeStep === 1" :label="$t('ProductManage.ConfigManage.quickDiagnosis')" prop="quickDiagnosis"
                        :rules="[{ required: false }]">
            <el-input v-model="formData.quickDiagnosis" :placeholder="$t('ProductManage.ConfigManage.quickDiagnosisPlaceholder')"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span class="dialog-footer">
        <el-button @click="prevStep" :disabled="activeStep === 0" v-if="activeStep === 1" class="custom-reset-button">{{ $t('ProductManage.ConfigManage.previousStep') }}</el-button>
        <el-button @click="nextStep" v-if="activeStep === 0"
                   :class="{ 'custom-reset-button': isFormValid, 'button-disabled-color': !isFormValid }"
                   :disabled="!isFormValid">{{ $t('ProductManage.ConfigManage.nextStep') }}
        </el-button>
        <el-button class="button-background-color" @click="saveData" v-if="activeStep === 1">{{ $t('ProductManage.ConfigManage.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [dialogMixin],
  components: {Plus},
  data() {
    return {
      dialogVisible: false,
      activeStep: 0,
      stepsCompleted: [false, false, false, false], // 初始状态，只有第一步是可点击的

      formData: {
        nameCn: "",
        zh_desc: "",
        nameEn: "",
        en_desc: "",
        quickDiagnosis: "",
        nameIt: "",
        it_desc: "",
        nameFr: "",
        fr_desc: "",
        nameDe: "",
        de_desc: "",
        nameNl: "",
        nl_desc: "",
        nameJa: "",
        ja_desc: "",
        nameSe: "",
        se_desc: "",
        nameHu: "",
        hu_desc: "",
        namePl: "",
        pl_desc: "",
      },
      languages: [
        { label: 'ProductManage.ConfigManage.it', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameItPlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descItPlaceholder', prop: 'nameIt', descProp: 'it_desc' },
        { label: 'ProductManage.ConfigManage.fr', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameFrPlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descFrPlaceholder', prop: 'nameFr', descProp: 'fr_desc' },
        { label: 'ProductManage.ConfigManage.de', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameDePlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descDePlaceholder', prop: 'nameDe', descProp: 'de_desc' },
        { label: 'ProductManage.ConfigManage.nl', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameNlPlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descNlPlaceholder', prop: 'nameNl', descProp: 'nl_desc' },
        { label: 'ProductManage.ConfigManage.ja', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameJaPlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descJaPlaceholder', prop: 'nameJa', descProp: 'ja_desc' },
        { label: 'ProductManage.ConfigManage.sv', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameSePlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descSePlaceholder', prop: 'nameSe', descProp: 'se_desc' },
        { label: 'ProductManage.ConfigManage.hu', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNameHuPlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descHuPlaceholder', prop: 'nameHu', descProp: 'hu_desc' },
        { label: 'ProductManage.ConfigManage.pl', descLabel: 'ProductManage.ConfigManage.desc', placeholder: 'ProductManage.ConfigManage.faultNamePlPlaceholder', descPlaceholder: 'ProductManage.ConfigManage.descPlPlaceholder', prop: 'namePl', descProp: 'pl_desc' }
      ]
    };
  },
  props: {
    saveMethod: {
      type: Function,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    }
  },
  computed: {
    isFormValid() {
      return this.formData.nameCn.trim() !== '' && this.formData.nameEn.trim() !== '';
    }
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    nextStep() {
      if (this.activeStep === 0) {
        // 手动触发表单验证
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.activeStep = 1;
          }
        });
      } else if (this.activeStep === 1) {
        this.activeStep = 2;
      }
    },

    changeStep(targetStep) {
      // 检查所有先前的步骤是否已完成
      for (let i = 0; i < targetStep - 1; i++) {
        if (!this.stepsCompleted[i]) {
          return; // 如果有任何一个步骤未完成，不允许更改步骤
        }
      }
      this.step = targetStep; // 更改步骤
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },

    async saveData() {
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          // 转换 formData 键名
          const transformedData = {
            device_type: this.deviceType,
            zh: this.formData.nameCn,
            zh_desc: this.formData.zh_desc,
            en: this.formData.nameEn,
            en_desc: this.formData.en_desc,
            it: this.formData.nameIt,
            it_desc: this.formData.it_desc,
            fr: this.formData.nameFr,
            fr_desc: this.formData.fr_desc,
            de: this.formData.nameDe,
            de_desc: this.formData.de_desc,
            nl: this.formData.nameNl,
            nl_desc: this.formData.nl_desc,
            ja: this.formData.nameJa,
            ja_desc: this.formData.ja_desc,
            sv: this.formData.nameSe,
            sv_desc: this.formData.sv_desc,
            hu: this.formData.nameHu,
            hu_desc: this.formData.hu_desc,
            pl: this.formData.namePl,
            pl_desc: this.formData.pl_desc,
            faq_url: this.formData.quickDiagnosis
          };

          try {
            await this.saveMethod(transformedData);
            this.dialogVisible = false;
            this.resetForm();
            this.$emit('dataSaved'); // 通知父组件数据已保存
          } catch (error) {
            console.error('Error:', error);
          }
        }
      });
    },
    resetForm() {
      this.activeStep = 0;
      for (const key in this.formData) {
        this.formData[key] = "";
      }
    },
  },
};
</script>

<style scoped>
:deep(.el-upload-list){
  width: 500px;
}
:deep(.el-upload-list__item-name){
  width: 300px;
}

.steps-container {
  margin-bottom: 20px; /* 这里设置你想要的间距大小 */
}
</style>