<template>
  <div class="add-motor">
    <el-button class="button-background-color" @click="showAddDialog">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp; {{ $t('ProductManage.ConfigManage.add') }}
    </el-button>

    <el-dialog style="text-align: left;" :title="$t('ProductManage.ConfigManage.add')" v-model="addDialogVisible"
               width="600" @open="disableScroll" @close="handleDialogClose">

      <hr class="top-separator"/>

      <!-- 弹窗内容 -->
      <el-form :model="firmwareForm" ref="firmwareForm" :rules="firmwareFormRules" label-width="300px"
               label-position="top">
        <el-row :gutter="20">

          <el-col :span="24">

            <el-form-item prop="compatibleOrganization">
              <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('ProductManage.ConfigManage.compatibilityOrganization') }}</label>
              <el-tree-select v-model="firmwareForm.compatibleOrganization" :data="organizationOptions"
                              :props="{ label: 'label', children: 'children' }" show-checkbox default-expand-all
                              multiple
                              :placeholder="$t('ProductManage.ConfigManage.compatibilityOrganizationPlaceholder')"
                              :render-after-expand="false" clearable style="width: 100%;" filterable check-strictly
                              check-on-click-node/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="compatibleModel">
              <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('ProductManage.adaptationModel') }}</label>
              <el-select v-model="firmwareForm.compatibleModel" multiple
                         :placeholder="$t('ProductManage.ConfigManage.compatibleModelPlaceholder')" style="width: 100%;"
                         filterable>
                <el-option v-for="item in compatibleModelsOptions" :key="item.id" :label="item.name" :value="item.id"/>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ConfigManage.firmwareName')" prop="firmwareName">
              <el-input v-model="firmwareForm.firmwareName"
                        :placeholder="$t('ProductManage.ConfigManage.firmwareNamePlaceholder')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ConfigManage.firmwareDescription')" prop="firmwareDescription">
              <el-input type="textarea" :rows="3" v-model="firmwareForm.firmwareDescription"
                        :placeholder="$t('ProductManage.ConfigManage.firmwareDescriptionPlaceholder')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">

            <el-form-item label="" prop="fileList" style="margin-top: 7px;margin-bottom: 5px;">
              <el-upload v-model:file-list="firmwareForm.fileList" :limit="1" :before-upload="handleBeforeUpload"
                         accept=".bin">
                <el-button class="upload-button">
                  <el-icon>
                    <Upload/>
                  </el-icon>
                  {{ $t('ProductManage.ConfigManage.uploadFirmware') }}
                </el-button>
              </el-upload>

            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <hr class="dialog-separator"/>

      <div class="dialog-footer">
        <el-button @click="addDialogVisible = false" class="custom-reset-button">
          {{ $t('ProductManage.ConfigManage.cancel') }}
        </el-button>
        <el-button class="button-background-color" @click="addFirmWare">
          {{ $t('ProductManage.ConfigManage.confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Plus, Upload} from "@element-plus/icons-vue";
import {addFirmware, uploadFileData} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  components: {Upload, Plus},
  mixins: [dialogMixin],
  data() {
    return {
      addDialogVisible: false,
      firmwareForm: {
        firmwareName: '',
        compatibleModel: [],
        compatibleOrganization: [],
        firmwareDescription: '',
        fileList: [],
        uploadedFile: '', // 用于存储单个文件标识符
        fileSize: 0
      },
      firmwareFormRules: {
        firmwareName: [
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.firmwareNamePattern'),
            trigger: 'blur',
          },
        ],
        compatibleModel: [
          {required: true, message: this.$t('ProductManage.compatibleModelRequired'), trigger: 'change'},
        ],
        compatibleOrganization: [
          {required: true, message: this.$t('ProductManage.compatibleOrganizationRequired'), trigger: 'change'},
        ],

      },
      attrs: {
        multiple: true,
      },
      selectWidth: 'auto', // 默认宽度或你的选择

    };
  },
  props: {
    isMotor: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function,
      required: true,
    },
    compatibleModelsOptions: Array,
    organizationOptions: Array
  },
  methods: {
    handleDialogClose() {
      this.resetForm();
      this.enableScroll();
    },
    showAddDialog() {
      this.addDialogVisible = true;
    },
    resetForm() {
      this.$refs.firmwareForm.resetFields();
    },
    async addFirmWare() {
      // 使用 Element UI 的表单校验方法
      await this.$refs.firmwareForm.validate(async (valid) => {
        if (valid) {
          if (!this.firmwareForm.uploadedFile) {
            this.$message.error(this.$t('ProductManage.selectFirmwareFile'));
            return;
          }
          try {
            // 如果校验通过且文件已选择，执行添加固件的操作
            const formData = {
              type: this.isMotor ? 0 : 2,
              name: this.firmwareForm.firmwareName,
              model_ids: this.firmwareForm.compatibleModel,
              organization_ids: this.firmwareForm.compatibleOrganization,
              desc: this.firmwareForm.firmwareDescription,
              file: this.firmwareForm.uploadedFile,
              file_name: this.firmwareForm.fileList[0].name,
              file_size: this.firmwareForm.fileSize
            };
            const response = await addFirmware(formData);
            if (response?.code === 0) {
              this.addDialogVisible = false;
              this.resetForm();
              await this.loadData();
              this.$message.success(this.$t('ProductManage.firmwareAddSuccess'));

            } else {
              this.$message.error(response?.message || this.$t('ProductManage.addDataFailed'));

            }
          } catch (error) {
            this.$message.error(this.$t('ProductManage.retryAddDataFailed'));
          }
        } else {
          this.$message.error(this.$t('ProductManage.checkInputValidation'));

        }
      });
    },
    async handleBeforeUpload(file) {
      // 这里可以执行你的上传逻辑
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);
        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ProductManage.uploadSuccess'));

          const fileData = response;
          this.firmwareForm.fileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.firmwareForm.uploadedFile = fileData.key; // 假设 response.key 是文件标识符
          this.firmwareForm.fileSize = fileData.fileSize;
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ProductManage.uploadFailed'));
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ProductManage.uploadException'));
      }
      return false;
    },
  },
};
</script>

<style scoped>
:deep(.el-upload-list){
  width: 500px;
}
:deep(.el-upload-list__item-name){
  width: 300px;
}

/* 去除弹框内容的内边距 */
:deep(.el-dialog__body) {
  padding-bottom: 0;
  /* 或者您想要的较小的值 */
}

/* 如果您还想调整弹框的顶部、左侧或右侧内边距 */
:deep( .el-dialog__body) {
  padding: 20px 15px 5px 20px;
  /* 顶部、左右侧保持 20px 内边距，底部为 0 */
}

/* 如果 .el-dialog 有额外的样式需要覆盖 */
:deep( .el-dialog ) {
  padding: 0;
  /* 如果您可以控制弹窗的内边距，将其设置为0 */
}

:deep( .el-form-item__label) {
  margin-bottom: 0;
  /* 减少label下方的外边距 */
  padding-bottom: 0;
  /* 减少label的内边距 */
}

.add-motor {
  text-align: left;
  margin-top: 5px;
  margin-left: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
