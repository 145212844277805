<template>
  <div class="data-table">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" class="table-data"
              :row-style="{ height: '57px' }">
      <!-- 表格列定义 -->
      <el-table-column prop="faultName" :label="$t('ProductManage.ConfigManage.faultName')"
      ></el-table-column>
      <el-table-column prop="userManualLink" :label=" $t('ProductManage.ConfigManage.helpManual')"
      >
        <template v-slot="scope">
          <el-link :underline="false" :href="scope.row.userManualLink" target="_blank">
            <span class="link-text-style"> {{ scope.row.userManualLink }}</span>
          </el-link>
        </template>
      </el-table-column>

      <el-table-column :label=" $t('ProductManage.ConfigManage.operation')">
        <template v-slot="scope">
          <el-button link @click="editMotor(scope.row)"><span
              class="link-text-style"> {{ $t('ProductManage.ConfigManage.edit') }}</span></el-button>
          <el-popconfirm :title=" $t('ProductManage.ConfigManage.sureDelete')" placement="top"
                         @confirm="deleteMotorFault(scope.row)"
                         :confirm-button-text="$t('ProductManage.ConfigManage.confirm')"
                         :cancel-button-text="$t('ProductManage.ConfigManage.cancel')"
                         confirm-button-type="success"
                         width="250"
          >
            <template #reference>
              <el-button link><span class="link-text-delete">{{ $t('ProductManage.ConfigManage.delete') }}</span>
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>
  </div>

  <el-dialog v-model="dialogVisible" :title="$t('ProductManage.ConfigManage.editTitle')" :width="1200"
             style="text-align: left">
    <div class="steps-container">
      <el-steps :active="activeStep" finish-status="success" align-center>
        <el-step :title="$t('ProductManage.ConfigManage.faultCode')" @click="changeStep(0)"></el-step>
        <el-step :title="$t('ProductManage.ConfigManage.quickDiagnosis')" @click="changeStep(1)"></el-step>
      </el-steps>
    </div>
    <div>
      <el-form ref="form" :model="formData" label-width="150px">
        <el-row v-if="activeStep === 0" :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('ProductManage.ConfigManage.zh')" prop="nameCn"
                          :rules="[{ required: true, message: $t('ProductManage.ConfigManage.nameCnMessage') }]">
              <el-input v-model="formData.nameCn"
                        :placeholder="$t('ProductManage.ConfigManage.nameCnPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('ProductManage.ConfigManage.desc')" prop="zh_desc"
                          :rules="[{ required: false }]">
              <el-input v-model="formData.zh_desc"
                        :placeholder="$t('ProductManage.ConfigManage.zhDescPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="activeStep === 0" :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('ProductManage.ConfigManage.en')" prop="nameEn"
                          :rules="[{ required: true, message: $t('ProductManage.ConfigManage.nameEnMessage') }]">
              <el-input v-model="formData.nameEn"
                        :placeholder="$t('ProductManage.ConfigManage.nameEnPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('ProductManage.ConfigManage.desc')" prop="en_desc"
                          :rules="[{ required: false }]">
              <el-input v-model="formData.en_desc"
                        :placeholder="$t('ProductManage.ConfigManage.enDescPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 其他语言的输入框 -->
        <template v-if="activeStep === 0">
          <el-row v-for="lang in languages" :key="lang.prop" :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t(lang.label)" :prop="lang.prop" :rules="[{ required: false }]">
                <el-input :placeholder="$t(lang.placeholder)" v-model="formData[lang.prop]"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t(lang.descLabel)" :prop="lang.descProp" :rules="[{ required: false }]">
                <el-input :placeholder="$t(lang.descPlaceholder)" v-model="formData[lang.descProp]"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <el-form-item v-if="activeStep === 1" :label="$t('ProductManage.ConfigManage.quickDiagnosis')"
                      prop="quickDiagnosis"
                      :rules="[{ required: false }]">
          <el-input v-model="formData.quickDiagnosis"
                    :placeholder="$t('ProductManage.ConfigManage.quickDiagnosisPlaceholder')"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <span class="dialog-footer">
        <el-button @click="prevStep" :disabled="activeStep === 0" v-if="activeStep === 1"
                   class="custom-reset-button">{{ $t('ProductManage.ConfigManage.prevStep') }}</el-button>
        <el-button @click="nextStep" v-if="activeStep === 0"
                   :class="{ 'custom-reset-button': isFormValid, 'button-disabled-color': !isFormValid }"
                   :disabled="!isFormValid"
        >{{ $t('ProductManage.ConfigManage.nextStep') }}</el-button>
        <el-button class="button-background-color" @click="saveData"
                   v-if="activeStep === 1">{{ $t('ProductManage.ConfigManage.confirm') }}</el-button>
      </span>
  </el-dialog>

</template>

<script>
import resizeMixin from "@/mixins/resizeMixin";
import {deleteFaultCode, getFaultCode, updateFaultCode} from "@/api/api";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot},
  mixins: [resizeMixin],

  data() {
    return {
      addDialogVisible: false,
      motorForm: {
        faultName: '',
        userManualLink: [],
      },
      motorFormRules: {
        faultName: [
          {required: true, message: this.$t('ProductManage.ConfigManage.faultNameRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.ConfigManage.faultNamePattern'),
            trigger: 'blur',
          },
        ],
      },
      dialogVisible: false,
      activeStep: 0,
      editMode: false,
      stepsCompleted: [false, false, false, false], // 初始状态，只有第一步是可点击的

      formData: {
        id: null,
        nameCn: "",
        zh_desc: "",
        nameEn: "",
        en_desc: "",
        quickDiagnosis: "",
        nameIt: "",
        it_desc: "",
        nameFr: "",
        fr_desc: "",
        nameDe: "",
        de_desc: "",
        nameNl: "",
        nl_desc: "",
        nameJa: "",
        ja_desc: "",
        nameSe: "",
        se_desc: "",
        nameHu: "",
        hu_desc: "",
        namePl: "",
        pl_desc: "",
      },
      languages: [
        {
          label: 'ProductManage.ConfigManage.it',
          placeholder: 'ProductManage.ConfigManage.nameItPlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.itDescPlaceholder',
          prop: 'nameIt',
          descProp: 'it_desc'
        },
        {
          label: 'ProductManage.ConfigManage.fr',
          placeholder: 'ProductManage.ConfigManage.nameFrPlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.frDescPlaceholder',
          prop: 'nameFr',
          descProp: 'fr_desc'
        },
        {
          label: 'ProductManage.ConfigManage.de',
          placeholder: 'ProductManage.ConfigManage.nameDePlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.deDescPlaceholder',
          prop: 'nameDe',
          descProp: 'de_desc'
        },
        {
          label: 'ProductManage.ConfigManage.nl',
          placeholder: 'ProductManage.ConfigManage.nameNlPlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.nlDescPlaceholder',
          prop: 'nameNl',
          descProp: 'nl_desc'
        },
        {
          label: 'ProductManage.ConfigManage.ja',
          placeholder: 'ProductManage.ConfigManage.nameJaPlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.jaDescPlaceholder',
          prop: 'nameJa',
          descProp: 'ja_desc'
        },
        {
          label: 'ProductManage.ConfigManage.sv',
          placeholder: 'ProductManage.ConfigManage.nameSePlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.seDescPlaceholder',
          prop: 'nameSe',
          descProp: 'se_desc'
        },
        {
          label: 'ProductManage.ConfigManage.hu',
          placeholder: 'ProductManage.ConfigManage.nameHuPlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.huDescPlaceholder',
          prop: 'nameHu',
          descProp: 'hu_desc'
        },
        {
          label: 'ProductManage.ConfigManage.pl',
          placeholder: 'ProductManage.ConfigManage.namePlPlaceholder',
          descLabel: 'ProductManage.ConfigManage.desc',
          descPlaceholder: 'ProductManage.ConfigManage.plDescPlaceholder',
          prop: 'namePl',
          descProp: 'pl_desc'
        },
      ],
    };
  },
  props: {
    tableData: Array, // 电机数据
    deviceType: {
      type: String,
      required: true
    }
  },
  emits: ['editMotor', 'deleteMotor'], // 在这里声明自定义事件
  computed: {
    isFormValid() {
      return this.formData.nameCn.trim() !== '' && this.formData.nameEn.trim() !== '';
    },
  },
  methods: {
    async deleteMotorFault(row) {
      try {
        await deleteFaultCode(row.id);
        this.$emit('deleteMotor'); // 通知父组件已删除
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.deleteError'), error);
      }
    },
    async editMotor(row) {
      try {
        const faultCodeData = await getFaultCode(row.id);
        this.formData = {
          id: faultCodeData.data.id,
          nameCn: faultCodeData.data.zh,
          zh_desc: faultCodeData.data.zh_desc,
          nameEn: faultCodeData.data.en,
          en_desc: faultCodeData.data.en_desc,
          nameIt: faultCodeData.data.it,
          it_desc: faultCodeData.data.it_desc,
          nameFr: faultCodeData.data.fr,
          fr_desc: faultCodeData.data.fr_desc,
          nameDe: faultCodeData.data.de,
          de_desc: faultCodeData.data.de_desc,
          nameNl: faultCodeData.data.nl,
          nl_desc: faultCodeData.data.nl_desc,
          nameJa: faultCodeData.data.ja,
          ja_desc: faultCodeData.data.ja_desc,
          nameSe: faultCodeData.data.sv,
          se_desc: faultCodeData.data.sv_desc,
          nameHu: faultCodeData.data.hu,
          hu_desc: faultCodeData.data.hu_desc,
          namePl: faultCodeData.data.pl,
          pl_desc: faultCodeData.data.pl_desc,
          quickDiagnosis: faultCodeData.data.faq_url
        };
        this.dialogTitle = this.$t('ProductManage.ConfigManage.editTitle');
        this.editMode = true;
        this.dialogVisible = true;
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.getError'), error);
      }
    },
    addMotorData() {
      this.addDialogVisible = false;
    },
    handleChange() {

    },
    openDialog() {
      this.editMode = false;
      this.dialogVisible = true;
      this.resetForm();
    },
    nextStep() {
      if (this.activeStep === 0) {
        // 手动触发表单验证
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.activeStep = 1;
          }
        });
      } else if (this.activeStep === 1) {
        this.activeStep = 2;
      }
    },
    changeStep(targetStep) {
      this.activeStep = targetStep;
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    resetForm() {
      this.activeStep = 0;
      for (const key in this.formData) {
        this.formData[key] = "";
      }
    },
    async saveData() {
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          const transformedData = {
            device_type: this.deviceType,
            zh: this.formData.nameCn,
            zh_desc: this.formData.zh_desc,
            en: this.formData.nameEn,
            en_desc: this.formData.en_desc,
            it: this.formData.nameIt,
            it_desc: this.formData.it_desc,
            fr: this.formData.nameFr,
            fr_desc: this.formData.fr_desc,
            de: this.formData.nameDe,
            de_desc: this.formData.de_desc,
            nl: this.formData.nameNl,
            nl_desc: this.formData.nl_desc,
            ja: this.formData.nameJa,
            ja_desc: this.formData.ja_desc,
            sv: this.formData.nameSe,
            sv_desc: this.formData.sv_desc,
            hu: this.formData.nameHu,
            hu_desc: this.formData.hu_desc,
            pl: this.formData.namePl,
            pl_desc: this.formData.pl_desc,
            faq_url: this.formData.quickDiagnosis
          };
          try {
            if (this.editMode) {
              await updateFaultCode(this.formData.id, transformedData);
              this.$message.success(this.$t('ProductManage.ConfigManage.updateSuccess'));
            } else {
              await this.saveMethod(transformedData);
              this.$message.success(this.$t('ProductManage.ConfigManage.addSuccess'));
            }
            this.dialogVisible = false;
            this.resetForm();
            this.$emit('dataSaved'); // 通知父组件数据已保存
          } catch (error) {
            this.$message.error(this.$t('ProductManage.ConfigManage.saveError'));
            console.error(this.$t('ProductManage.ConfigManage.saveError'), error);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
:deep(.el-upload-list) {
  width: 500px;
}

:deep(.el-upload-list__item-name) {
  width: 300px;
}

.data-table {
  margin: 10px 20px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 5px 10px; /* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 15px 30px; /* 在中等屏幕下增加间隔 */
  }

}

.table-data {
  width: 100%;
  margin-top: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
