<template>
  <div>
    <add-button :loadData="loadData" />
    <MotorParameterFileTable :tableData="tableData" @delete-motor-parameterFile="deleteMotorParameterFile"
      :loadData="loadData" :loading="loading"/>
    <pagination-bar :totalRecords="totalRecords" :current-page="currentPage" :page-size="pageSize"
      @page-change="handlePageChange" @page-size-change="handlePageSizeChange" />
  </div>
</template>

<script>
import AddButton from '@/views/ProductManage/ConfigManagement/components/Motor/ParameterFileComponent/AddButton.vue';
import MotorParameterFileTable
  from '@/views/ProductManage/ConfigManagement/components/Motor/ParameterFileComponent/MotorParameterFileTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import { deleteFirmware, getFirmware } from "@/api/api";

export default {
  data() {
    return {
      tableData: [], // 电机数据
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      loading: false, // 加载状态
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {

    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.loadData(); // 重新加载数据
    },
    handlePageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      this.loadData(); // 重新加载数据
    },
    async loadData() {
      this.isSearching = false;
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        const data = await getFirmware(page, pageSize, { "type-eq": 1 });

        const filteredData = data.data.filter(item => item.type === 1);
        // 将从服务器获取的数据进行字段重命名
        this.tableData = filteredData.map(item => ({
          type: item.type,
          firmware_id: item.id,
          fileName: item.file_name,
          parameterName: item.name,
          fileDescription: item.desc, // 根据值映射中文名称
          file: item.file,
          file_url: item.file_url,
          compatibleModel: item.productModels.map(model => model.name).join(', '),
          compatibleOrganization: item.organizations.map(org => org.name).join(', '),
        }));
        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta
          .per_page === 'string' ? parseInt(data.meta
            .per_page, 10) : data.meta
          .per_page;
      } catch (error) {
        this.$message.error(this.$t('ProductManage.loadDataFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },
    async deleteMotorParameterFile(motorFirmWare) {
      // 处理删除电机逻辑
      try {
        await deleteFirmware(motorFirmWare.firmware_id);
        await this.loadData();
      } catch (error) {
        // 处理错误，例如输出错误提示或进行其他处理
        console.error(this.$t('ProductManage.deleteDataFailed'));
      }
    },
  },
  components: {
    AddButton,
    MotorParameterFileTable,
    PaginationBar,
  },
};
</script>
