<template>
  <div class="data-table" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" class="table-height"
              :row-style="{ height: '57px' }">
      <!-- 表格列定义 -->
      <el-table-column prop="parameterName" :label="$t('ProductManage.ConfigManage.documentName')"

      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.parameterName">
            <span>{{ scope.row.parameterName }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="fileDescription"
                       :label="$t('ProductManage.ConfigManage.documentDescription')"
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.fileDescription">
            <span>{{ scope.row.fileDescription }}</span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="file" :label=" $t('ProductManage.ConfigManage.document')"
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.fileName">

            <!-- 使用el-link组件创建下载链接 -->
            <el-link
                :underline="false"
                @click="downloadFirmware(scope.row.file_url)"
            >
              <span class="link-text-style">{{ truncatedText(scope.row.fileName) }}</span>
            </el-link>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="compatibleModel"
                       :label="$t('ProductManage.ConfigManage.compatibleModel')"
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.compatibleModel">
            <span>{{ scope.row.compatibleModel }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="compatibleOrganization"
                       :label="$t('ProductManage.ConfigManage.compatibilityOrganization')"
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top-start" :content="scope.row.compatibleOrganization">
            <span>{{ scope.row.compatibleOrganization }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column :label="$t('ProductManage.ConfigManage.operation')">
        <template v-slot="scope">
          <el-button link @click="editMotorParameterFile(scope.row)"><span
              class="link-text-style">{{ $t('ProductManage.ConfigManage.edit') }}</span>
          </el-button>
          <el-popconfirm :title="$t('ProductManage.ConfigManage.sureDelete')" placement="top"
                         @confirm="deleteMotor(scope.row)"
                         :confirm-button-text="$t('ProductManage.ConfigManage.confirm')"
                         :cancel-button-text="$t('ProductManage.ConfigManage.cancel')"
                         confirm-button-type="success"
                         width="250"
          >
            <template #reference>
              <el-button link><span class="link-text-delete">{{ $t('ProductManage.ConfigManage.delete') }}</span>
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>

        <template v-slot:empty>
          <EmptySlot/>
        </template>

    </el-table>
  </div>
  <!--编辑弹框-->
  <el-dialog
      style="text-align: left;"
      :title="$t('ProductManage.ConfigManage.edit')"
      v-model="addDialogVisible"
      width="600"
      @closed="resetForm"
  >
    <hr class="top-separator"/>

    <!-- 编辑弹窗 -->
    <el-form :model="motorParameterFileForm" ref="motorParameterFileForm" :rules="motorParameterFileFormRules"
             label-position="top"
             label-width="250px">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="$t('ProductManage.ConfigManage.compatibilityOrganization')"
                        prop="compatibleOrganization">
            <el-tree-select
                v-model="motorParameterFileForm.compatibleOrganization"
                :data="organizationOptions"
                :props="{ label: 'label', children: 'children' }"
                show-checkbox
                check-strictly
                check-on-click-node
                default-expand-all
                multiple filterable
                :placeholder="$t('ProductManage.ConfigManage.compatibilityOrganizationPlaceholder')"
                :render-after-expand="false"
                clearable
                style="width: 100%;"
            />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="compatibleModel">
            <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('ProductManage.adaptationModel') }}</label>

            <el-select
                v-model="motorParameterFileForm.compatibleModel"
                multiple filterable
                :placeholder="$t('ProductManage.ConfigManage.compatibleModelPlaceholder')"
                style="width: 100%;"
            >
              <el-option
                  v-for="item in compatibleModelsOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item :label="$t('ProductManage.ConfigManage.documentName')" prop="parameterName">
            <el-input v-model="motorParameterFileForm.parameterName"
                      :placeholder="$t('ProductManage.ConfigManage.documentNamePlaceholder')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">

          <el-form-item :label="$t('ProductManage.ConfigManage.documentDescription')" prop="fileDescription">
            <el-input type="textarea" v-model="motorParameterFileForm.fileDescription"
                      :placeholder=" $t('ProductManage.ConfigManage.documentDescriptionPlaceholder')"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">

          <el-form-item label="" prop="fileList" style="margin-top: 7px;margin-bottom: 5px;">
            <!-- 上传附件的代码，可以使用 el-upload -->
            <el-upload v-model:file-list="motorParameterFileForm.fileList" class="upload-demo"
                       :before-upload="handleBeforeUpload"
                       :limit="1"
                       :on-change="handleChange" accept=".bin">
              <el-button class="upload-button">
                <el-icon>
                  <Upload/>
                </el-icon>
                {{ $t('ProductManage.ConfigManage.uploadConfigurationDocument') }}
              </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="addDialogVisible = false" class="custom-reset-button">
        {{ $t('ProductManage.ConfigManage.cancel') }}
      </el-button>
      <el-button class="button-background-color" @click="savedMotorParameterFileData">
        {{ $t('ProductManage.ConfigManage.confirm') }}
      </el-button>
    </div>
  </el-dialog>

</template>

<script>
import {
  getOrganizationsData,
  getProductModelData,
  listToTree,
  showFirmware, updateFirmware,
  uploadFileData
} from "@/api/api";
import {Upload} from "@element-plus/icons-vue";
import resizeMixin from "@/mixins/resizeMixin";
import dialogMixin from "@/mixins/dialogMixin";
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot, Upload},
  emits: ['delete-motor-parameterFile'], // 在这里声明自定义事件

  data() {
    return {
      addDialogVisible: false,
      deleteDialogVisible: false,
      motorParameterFileForm: {
        parameterName: '',
        compatibleModel: [],
        compatibleOrganization: [],
        compatible_organization_ids: [],
        fileDescription: '',
        fileList: [],
        uploadedFile: '', // 用于存储单个文件标识符
        fileSize: 0
      },
      motorParameterFileFormRules: {
        parameterName: [
          {required: true, message: this.$t('ProductManage.parameterNameRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.parameterNamePattern'),
            trigger: 'blur',
          },
        ],
        compatibleModel: [
          {required: true, message: this.$t('ProductManage.compatibleModelRequired'), trigger: 'change'},
        ],
        compatibleOrganization: [
          {required: true, message: this.$t('ProductManage.compatibleOrganizationRequired'), trigger: 'change'},
        ],

      },
      attrs: {
        multiple: true,
      },
      organizationOptions: [],
      selectWidth: 'auto',
      compatibleModelsOptions: [],

    };
  },
  props: {
    tableData: Array, // 电机数据
    loadData: {
      type: Function,
      required: true,
    },
    loading: Boolean  // 接收 loading 状态
  },

  mixins: [resizeMixin,dialogMixin],
  mounted() {
    this.fetchOrganizationOptions();
    this.fetchMotorCompatibleModels();
  },
  methods: {
    async editMotorParameterFile(motorParameterFile) {

      this.addDialogVisible = true;
      try {
        const fileId = motorParameterFile.firmware_id;
        const fileInformation = await showFirmware(fileId);

        this.motorParameterFileForm.fileId = fileInformation.data.id;
        this.motorParameterFileForm.parameterName = fileInformation.data.name;

        this.motorParameterFileForm.compatibleModel = fileInformation.data.productModels.map(model => model.id);

        this.motorParameterFileForm.compatibleOrganization = fileInformation.data.organizations.map(model => model.id);
        this.motorParameterFileForm.fileDescription = fileInformation.data.desc;
        this.motorParameterFileForm.uploadedFile = fileInformation.data.file;
        // 处理文件URL展示
        const fileUrl = fileInformation.data.file_url; // 假设这是你从后端获取到的文件URL
        const fileName = fileInformation.data.file_name; // 从URL中提取文件名

        this.motorParameterFileForm.fileList = [{
          name: fileName, // 文件名
          url: fileUrl, // 文件URL
          status: 'success' // 标记为已上传
        }];
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.editFailed'));
      }
    },
    deleteMotor(motor) {
      this.$emit('delete-motor-parameterFile', motor);
    },
    resetForm() {
      this.$refs.motorParameterFileForm.resetFields();
    },
    confirmDelete() {
      // 在成功删除后关闭确认删除弹框
      this.deleteDialogVisible = false;
    },
    handleDeleteClose(done) {
      // 关闭确认删除弹框时重置状态
      this.motorToDelete = null;
      done();
    },
    handleChange() {

    },
    async savedMotorParameterFileData() {
      try {
        // 检查是否已加载编辑数据
        if (!this.motorParameterFileForm) {
          this.$message.error(this.$t('ProductManage.parameterDataNotLoaded'));

          return;
        }
        // 调用更新接口，传递 file_id 和编辑后的 formData
        const file_id = this.motorParameterFileForm.fileId; // 请替换成你实际的 file_id
        const formData = {
          name: this.motorParameterFileForm.parameterName,
          model_ids: this.motorParameterFileForm.compatibleModel,
          organization_ids: this.motorParameterFileForm.compatibleOrganization,
          desc: this.motorParameterFileForm.fileDescription,
          file: this.motorParameterFileForm.uploadedFile,
          file_name: this.motorParameterFileForm.fileList[0].name,
          file_size: this.motorParameterFileForm.fileSize
        };
        await updateFirmware(file_id, formData);
        // 数据保存成功后，关闭编辑弹框
        this.addDialogVisible = false;
        await this.loadData();
      } catch (error) {
        // 处理错误，可以在界面上显示错误信息等
        this.$message.error(this.$t('ProductManage.ConfigManage.editFailed'));
      }
    },

    async fetchMotorCompatibleModels() {
      try {
        const response = await getProductModelData(0,1);
        this.compatibleModelsOptions = response.data;
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.fetchDataFail'));
      }
    },
    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.fetchOrganizationFailed'));
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    },
    async handleBeforeUpload(file) {
      // 这里可以执行你的上传逻辑
      try {
        // 例如使用 axios 手动上传文件
        const formData = new FormData();
        formData.append('file', file);

        const response = await uploadFileData(formData);
        if (response.code === 0) {
          // 处理成功的响应
          this.$message.success(this.$t('ProductManage.uploadSuccess'));
          const fileData = response;
          this.motorParameterFileForm.fileList.push({
            name: fileData.fileName, // 文件名
            key: fileData.key, // 文件名
            url: fileData.url // 文件访问URL
          });
          // 更新用于提交给后端的 uploadedFile 字符串
          this.motorParameterFileForm.uploadedFile = fileData.key; // 假设 response.key 是文件标识符
          this.motorParameterFileForm.fileSize = fileData.fileSize;
          return true; // 返回 true 或不返回任何内容，以允许文件上传
        } else {
          // 处理非200的响应
          this.$message.error(this.$t('ProductManage.uploadFailed'));
          return false; // 阻止文件上传
        }
      } catch (error) {
        // 处理异常
        this.$message.error(this.$t('ProductManage.uploadException'));
        return false; // 阻止文件上传
      }
    },
    downloadFirmware(downloadLink) {
      // 创建一个隐藏的 <a> 标签
      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', ''); // 尝试强制下载，但效果依赖于文件类型和服务器配置
      document.body.appendChild(link);
      link.click(); // 模拟点击
      document.body.removeChild(link); // 移除临时添加的 <a> 标签
    },
  },
};
</script>

<style scoped>
:deep(.el-upload-list){
  width: 500px;
}
:deep(.el-upload-list__item-name){
  width: 300px;
}
/* 去除弹框内容的内边距 */
:deep( .el-dialog__body ){
  padding-bottom: 0; /* 或者您想要的较小的值 */
}

/* 如果您还想调整弹框的顶部、左侧或右侧内边距 */
:deep( .el-dialog__body) {
  padding: 20px 15px 5px 20px; /* 顶部、左右侧保持 20px 内边距，底部为 0 */
}

:deep( .el-form-item__label) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.data-table {
  margin: 10px 20px; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: 5px 10px; /* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 15px 30px; /* 在中等屏幕下增加间隔 */
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
